import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { getComplainsListAction, changeComplainStatus } from '../../actions/complainsActions';

import { DataTable } from '../../components/DataTable';
import Paginator from '../../components/Paginator';

import './style.scss';

class ComplainsPage extends Component {
    state = {
        newComplains: true,
    };

    changePageAction = page => {
        const { newComplains } = this.state;
        this.props.getComplainsListAction(page + 1, !newComplains);
    };

    complainAction = data => {
        const { complainsList, changeComplainStatus } = this.props;
        let currentPage = complainsList.pagination.current;
        changeComplainStatus(data, true, currentPage);
    };

    tableColumns = () => {
        const { vocabulary } = this.props;
        const {
            v_areol_name,
            v_areol_author,
            v_complaint,
            v_complaint_author,
            v_action,
            v_areol_author_phone,
            v_complaint_author_phone,
            v_cancel_complaint,
        } = vocabulary;
        return [
            {
                title: v_areol_name,
                field: 'areolName',
                headerStyle: { width: 150, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                render: rowData => (
                    <Link to={`/areols/${rowData.areolId}`} className="complains-link">
                        {rowData.areolName}
                    </Link>
                ),
            },
            {
                title: v_areol_author,
                field: 'areolAautor',
                headerStyle: { width: 150, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                render: rowData => (
                    <Link to={`/users/${rowData.areolCreatorId}`} className="complains-link">
                        {rowData.areolAautor}
                    </Link>
                ),
            },
            {
                title: v_areol_author_phone,
                field: 'areolAuthorPhone',
                headerStyle: { width: 150, borderRight: '1px solid rgba(224, 224, 224, 1)' },
            },
            {
                title: v_complaint,
                field: 'complain',
                headerStyle: { borderRight: '1px solid rgba(224, 224, 224, 1)' },
            },
            {
                title: v_complaint_author,
                field: 'complainAautor',
                headerStyle: { width: 150, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                render: rowData => (
                    <Link to={`/users/${rowData.complainCreatorId}`} className="complains-link">
                        {rowData.complainAautor}
                    </Link>
                ),
            },
            {
                title: v_complaint_author_phone,
                field: 'complaintAuthorPhone',
                headerStyle: { width: 150, borderRight: '1px solid rgba(224, 224, 224, 1)' },
            },
            {
                title: v_action,
                field: 'action',
                headerStyle: { width: 150, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                render: rowData => (
                    <span className="complain-action" onClick={() => this.complainAction(rowData.complainId)}>
                        {v_cancel_complaint}
                    </span>
                ),
            },
        ];
    };

    setTableColumns = () => {
        const { newComplains } = this.state;
        let columns = this.tableColumns();
        return columns.reduce((filtered, item) => {
            if (newComplains || item.field !== 'action') {
                filtered.push(item);
            }
            return filtered;
        }, []);
    };

    tableData = () => {
        const { complainsList } = this.props;
        if (complainsList) {
            return complainsList.complains.map(item => ({
                areolName: item.areol.title,
                areolAautor:
                    item.areol.creator.firstName || item.areol.creator.lastName
                        ? item.areol.creator.firstName + ' ' + item.areol.creator.lastName
                        : '-',
                complain: item.text,
                complaintAuthorPhone: item.creator.phone,
                areolAuthorPhone: item.areol.creator.phone,
                complainAautor:
                    item.creator.firstName || item.creator.lastName
                        ? item.creator.firstName + ' ' + item.creator.lastName
                        : '-',
                action: !item.isVieved ? 'Отменить жалобу' : null,
                complainCreatorId: item.creator.id,
                areolCreatorId: item.areol.creator.id,
                areolId: item.areol.id,
                complainId: item.id,
            }));
        } else return [];
    };

    componentDidMount() {
        const { match, history } = this.props;
        let type = match.params.type;
        if (type === 'new') {
            this.setState({ newComplains: true });
            this.props.getComplainsListAction(1, false);
        } else if (type === 'old') {
            this.setState({ newComplains: false });
            this.props.getComplainsListAction(1, true);
        } else {
            history.push('/complaints/new');
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.type !== this.props.match.params.type) {
            let type = this.props.match.params.type;
            if (type === 'new') {
                this.setState({ newComplains: true });
                this.props.getComplainsListAction(1, false);
            } else if (type === 'old') {
                this.setState({ newComplains: false });
                this.props.getComplainsListAction(1, true);
            }
        }
    }

    render() {
        const { isFetching, complainsList, vocabulary } = this.props;
        const { newComplains } = this.state;
        const { v_complaints, v_new, v_watched, v_empty_data } = vocabulary;
        return (
            <div className="complains-page_wrapper">
                <div className="complains-page_header">
                    <Typography variant="h5">{v_complaints}</Typography>
                    {newComplains ? (
                        <>
                            <span>{v_new}</span>
                            <Link to={`/complaints/old`} className="complains-link">
                                {v_watched}
                            </Link>
                        </>
                    ) : (
                        <>
                            <Link to={`/complaints/new`} className="complains-link">
                                {v_new}
                            </Link>
                            <span>{v_watched}</span>
                        </>
                    )}
                </div>

                <DataTable
                    columns={this.setTableColumns()}
                    data={this.tableData()}
                    isLoading={isFetching}
                    options={{
                        rowStyle: rowData => ({
                            backgroundColor: newComplains ? '#EDF1F7' : 'white',
                        }),
                        actionsColumnIndex: 4,
                        maxBodyHeight: 530,
                    }}
                    localization={{
                        header: { actions: '' },
                        body: { emptyDataSourceMessage: v_empty_data },
                    }}
                />
                {!isFetching && complainsList && complainsList.pagination.last > 1 && (
                    <div className="pagination-container">
                        <Paginator
                            limit={1}
                            offset={complainsList.pagination.current - 1}
                            total={complainsList.pagination.last}
                            onClick={(e, offset) => this.changePageAction(offset)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    complainsList: state.complainsReducer.complainsList,
    isFetching: state.complainsReducer.isFetching,
    vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
    getComplainsListAction,
    changeComplainStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComplainsPage));
