import { queryUserDetail, queryChangeUserStatus } from '../queries/userDetailQueries';
import { initConnection } from '../services/apiConfig';

const getUserDetailData = () => ({
    type: 'GET_USER_DETAIL_DATA',
});
const getUserDetailDataSuccess = payload => ({
    type: 'GET_USER_DETAIL_DATA_SUCCESS',
    payload,
});
const getUserDetailDataError = () => ({
    type: 'GET_USER_DETAIL_DATA_ERROR',
});

export const getUserDetailAction = (userId, history) => async dispatch => {
    dispatch(getUserDetailData());
    try {
        let res = await initConnection().request(queryUserDetail, {
            userId,
        });
        if (!res.oneById) {
            dispatch(getUserDetailDataError());
            history.push('/users');
        }
        dispatch(getUserDetailDataSuccess(res.oneById));
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        history.push('/users');
        dispatch(getUserDetailDataError());
    }
};

export const changeUserStatus = (userId, status) => async dispatch => {
    dispatch(getUserDetailData());
    try {
        let res = await initConnection().request(queryChangeUserStatus, {
            userId,
            status,
        });
        if (res.setStatusUserAdmin) {
            dispatch(getUserDetailAction(userId));
        }
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getUserDetailDataError());
    }
};
