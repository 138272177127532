import React, { Component } from 'react';
import { Redirect, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import { checkUserAuth } from '../../actions/userActions';

class PrivateRoute extends Component {
    componentDidMount() {
        const { userData, checkUserAuth } = this.props;
        !userData.userAuth && checkUserAuth();
    }

    render() {
        const { userData, render, ...rest } = this.props;
        const { userAuth, isInitialFetching } = userData;
        return (
            <Route
                {...rest}
                render={props => (userAuth || isInitialFetching ? render() : <Redirect to={'/login'} />)}
            />
        );
    }
}
const mapStateToProps = state => ({
    userData: state.userReducer,
});

const mapDispatchToProps = {
    checkUserAuth,
};

export default connect(mapStateToProps, mapDispatchToProps)(PrivateRoute);
