import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from 'material-ui-flat-pagination';

const useStyles = makeStyles({
    rootCurrent: {
        background: '#898989',
        borderRadius: '0px',
        border: '1px solid #898989',
        borderRight: 'none',
        color: 'white',
        '&:hover': {
            background: '#5c5c5c',
            border: '1px solid #5c5c5c',
            borderRight: 'none',
        },
    },
    rootStandard: {
        background: 'white',
        borderRadius: '0px',
        color: '#898989',
        border: '1px solid #898989',
        borderRight: 'none',
    },
    rootEnd: {
        background: 'white',
        borderRadius: '0px',
        color: '#898989',
        border: '1px solid #898989',
        borderRight: 'none',
    },
    root: {
        '&:last-child': {
            borderRight: '1px solid #898989',
        },
    },
});

export default function Paginator(props) {
    const classes = useStyles();
    return <Pagination classes={classes} {...props} />;
}
