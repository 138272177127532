import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { DataTable } from '../../components/DataTable';
import Paginator from '../../components/Paginator';

import { getUserListAction, changeUserStatus } from '../../actions/userListActions';

import './style.scss';

class UsersPage extends Component {
    changePageAction = page => {
        this.props.getUserListAction(page + 1);
    };

    tableData = () => {
        const { userList } = this.props.userListData;
        if (userList) {
            return userList.users.map(item => ({
                name: item.firstName || item.lastName ? item.firstName + ' ' + item.lastName : 'Unknown',
                phone: item.phone,
                itemId: item.id,
                isActive: item.isActive,
                status: item.isActive ? 'active' : 'disable',
            }));
        } else return [];
    };

    setUserStatus = data => {
        const { itemId, status } = data;
        let currentPage = this.props.userListData.userList.pagination.current;
        this.props.changeUserStatus(itemId, status === 'disable' ? false : true, currentPage);
    };

    componentDidMount() {
        this.props.getUserListAction(1);
    }

    render() {
        const { history, vocabulary } = this.props;
        const { isFetching, userList } = this.props.userListData;
        const {
            v_users,
            v_name,
            v_phone,
            v_account_status,
            v_edit,
            v_cancel,
            v_save,
            v_active,
            v_disabled,
            v_empty_data,
        } = vocabulary;
        return (
            <div className="users-page_wrapper">
                <div className="users-page_header">
                    <Typography variant="h5">{v_users}</Typography>
                </div>
                <DataTable
                    columns={[
                        {
                            title: v_name,
                            field: 'name',
                            editable: 'never',
                            headerStyle: { width: 400, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                        },
                        {
                            title: v_phone,
                            field: 'phone',
                            editable: 'never',
                            headerStyle: { width: 400, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                        },
                        {
                            title: v_account_status,
                            field: 'status',
                            lookup: { active: v_active, disable: v_disabled },
                        },
                    ]}
                    data={this.tableData()}
                    isLoading={isFetching}
                    onRowClick={(e, rowData) => history.push(`users/${rowData.itemId}`)}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (newData.status !== oldData.status) {
                                        this.setUserStatus(newData);
                                    }
                                    resolve();
                                }, 1000);
                            }),
                    }}
                    options={{
                        rowStyle: rowData => ({
                            backgroundColor: Math.abs(rowData.tableData.id % 2) === 1 ? 'white' : '#EDF1F7',
                        }),
                        actionsColumnIndex: 4,
                    }}
                    localization={{
                        header: { actions: '' },
                        body: {
                            emptyDataSourceMessage: v_empty_data,
                            editTooltip: v_edit,
                            editRow: {
                                cancelTooltip: v_cancel,
                                saveTooltip: v_save,
                            },
                        },
                    }}
                />
                {!isFetching && (
                    <div className="pagination-container">
                        <Paginator
                            limit={1}
                            offset={userList && userList.pagination.current - 1}
                            total={userList && userList.pagination.last}
                            onClick={(e, offset) => this.changePageAction(offset)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.userReducer,
    userListData: state.userListReducer,
    vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
    getUserListAction,
    changeUserStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UsersPage));
