import { locales } from '../locale';

const initialState = {
    languages: [
        {
            short: 'ru',
            long: 'Русский',
        },
        {
            short: 'en',
            long: 'English',
        },
    ],
    vocabulary: locales.en,
    isFetching: false,
    isError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'CHANGE_LANGUAGE_REQUEST': {
            return {
                ...state,
                isFetching: true,
            };
        }
        case 'CHANGE_LANGUAGE': {
            return {
                ...state,
                isFetching: false,
                isError: false,
                vocabulary: locales[payload],
            };
        }
        case 'CHANGE_LANGUAGE_REQUEST_ERROR': {
            return {
                ...state,
                isFetching: false,
                isError: true,
            };
        }
        default: {
            return state;
        }
    }
};
