import { queryUserList, queryChangeUserStatus } from '../queries/usersQueries';
import { initConnection } from '../services/apiConfig';

const getUserListData = () => ({
    type: 'GET_USERS_LIST_DATA',
});
const getUserListDataSuccess = payload => ({
    type: 'GET_USERS_LIST_DATA_SUCCESS',
    payload,
});
const getUserListDataError = () => ({
    type: 'GET_USERS_LIST_DATA_ERROR',
});

export const getUserListAction = page => async dispatch => {
    dispatch(getUserListData());
    try {
        let res = await initConnection().request(queryUserList, {
            page,
        });
        let usersPayload = res.admin.users;
        dispatch(getUserListDataSuccess(usersPayload));
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getUserListDataError());
    }
};
export const changeUserStatus = (userId, status, currentPage) => async dispatch => {
    dispatch(getUserListData());
    try {
        let res = await initConnection().request(queryChangeUserStatus, {
            userId,
            status,
        });
        if (res.setStatusUserAdmin) {
            dispatch(getUserListAction(currentPage));
        }
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getUserListDataError());
    }
};
