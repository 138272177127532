export const queryComplainsList = `
query complainsList($page: Int!, $newComplains: Boolean!){
	admin{
    complains(page: $page, viewed: $newComplains){
        complains{
            text
            id
            isViewed
            creator{
              id
              phone
              firstName
              lastName
            }
            areol{
              id
              title
              creator{
                id
                  phone
                  firstName
                  lastName
              }
            }
          }
      pagination{
        last
        current
        numItemsPerPage
        first
      }
    }
  }
}
`;

export const queryChangeComplainStatus = `
    mutation complainStatus($complainlId: Int!, $status: Boolean!){
        setStatusComplainAdmin(id: $complainlId, status:$status){
            id
            isViewed
          }
    }
`;
