import { queryAreolsList } from '../queries/areolsQueries';
import { initConnection } from '../services/apiConfig';

const getAreolsListData = () => ({
    type: 'GET_AREOLS_LIST_DATA',
});
const getAreolsListDataSuccess = payload => ({
    type: 'GET_AREOLS_LIST_DATA_SUCCESS',
    payload,
});
const getAreolsListDataError = () => ({
    type: 'GET_AREOLS_LIST_DATA_ERROR',
});

export const getAreolsListAction = (page, onlyAdmins) => async dispatch => {
    dispatch(getAreolsListData());
    try {
        let res = await initConnection().request(queryAreolsList, {
            page,
        });
        let areolsPayload = res.admin.areols;
        dispatch(getAreolsListDataSuccess(areolsPayload));
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getAreolsListDataError());
    }
};
