import { queryAreolDetail, queryAreolStatus } from '../queries/areolDetailQueries';
import { queryChangeComplainStatus } from '../queries/complainsQueries';
import { initConnection } from '../services/apiConfig';

const getAreolDetailData = () => ({
    type: 'GET_AREOL_DETAIL_DATA',
});
const getAreolDetailDataSuccess = payload => ({
    type: 'GET_AREOL_DETAIL_DATA_SUCCESS',
    payload,
});
const getAreolDetailDataError = () => ({
    type: 'GET_AREOL_DETAIL_DATA_ERROR',
});

export const getAreolDetailAction = (areolId, history, viewedComplains = true) => async dispatch => {
    dispatch(getAreolDetailData());
    try {
        let res = await initConnection().request(queryAreolDetail, {
            areolId,
            viewedComplains,
        });
        if (!res.areolById) {
            history.push('/areols');
            dispatch(getAreolDetailDataError());
        }
        dispatch(getAreolDetailDataSuccess(res.areolById));
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        history.push('/areols');
        dispatch(getAreolDetailDataError());
    }
};

export const changeAreolStatus = (areolId, areolStatus, history) => async dispatch => {
    dispatch(getAreolDetailData());
    try {
        let res = await initConnection().request(queryAreolStatus, {
            areolId,
            areolStatus,
        });
        if (res.setStatusAreolAdmin) {
            dispatch(getAreolDetailAction(areolId, history));
        }
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getAreolDetailDataError());
    }
};

export const changeAreolComplainStatus = (complainlId, areolId, history) => async dispatch => {
    dispatch(getAreolDetailData());
    try {
        let res = await initConnection().request(queryChangeComplainStatus, {
            complainlId,
            status: true,
        });
        if (res.setStatusComplainAdmin) {
            dispatch(getAreolDetailAction(areolId, history));
        }
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getAreolDetailData());
    }
};
