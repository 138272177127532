const initialState = {
    areolsList: null,
    isFetching: false,
    isError: false,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'GET_AREOLS_LIST_DATA': {
            return {
                ...state,
                isFetching: true,
            };
        }
        case 'GET_AREOLS_LIST_DATA_SUCCESS': {
            return {
                isFetching: false,
                areolsList: payload,
                isError: false,
            };
        }
        case 'GET_AREOLS_LIST_DATA_ERROR': {
            return {
                ...state,
                isFetching: false,
                isError: true,
            };
        }
        case 'RESET_ALL': {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};
