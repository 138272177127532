export const queryUserDetail = `
query oneById($userId: Int!){
  oneById(id: $userId){
    id
    phone
    firstName
    lastName
    isActive
    areols{
      id
      title
      longitude
      latitude
      altitude
    }
  }
}
`;

export const queryChangeUserStatus = `
mutation setStatusUser($userId:Int!, $status: Boolean!){
  setStatusUserAdmin(id: $userId, status: $status){
    id
    phone
    firstName
    lastName
    isActive
    areols{
      id
      title
      longitude
      latitude
      altitude
    }

  }
}
`;
