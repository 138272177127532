import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Typography } from '@material-ui/core';

import { getAreolsListAction } from '../../actions/areolsActions';

import { DataTable } from '../../components/DataTable';
import Paginator from '../../components/Paginator';

import './style.scss';

class AreolsPage extends Component {
    changePageAction = page => {
        this.props.getAreolsListAction(page + 1);
    };

    tableData = () => {
        const { areolsList } = this.props;
        if (areolsList) {
            return areolsList.areols.map(item => ({
                name: item.title ? item.title : '-',
                autor:
                    item.creator.firstName || item.creator.lastName
                        ? item.creator.firstName + ' ' + item.creator.lastName
                        : '-',
                item,
            }));
        } else return [];
    };

    componentDidMount() {
        this.props.getAreolsListAction(1);
    }

    render() {
        const { isFetching, areolsList, history, vocabulary } = this.props;
        const { v_areols, v_areol_name, v_author } = vocabulary;

        return (
            <div className="areols-page_wrapper">
                <div className="areols-page_header">
                    <Typography variant="h5">{v_areols}</Typography>
                </div>

                <DataTable
                    columns={[
                        {
                            title: v_areol_name,
                            field: 'name',
                            headerStyle: { width: 400, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                        },
                        {
                            title: v_author,
                            field: 'autor',
                            headerStyle: { borderRight: '1px solid rgba(224, 224, 224, 1)' },
                        },
                    ]}
                    data={this.tableData()}
                    isLoading={isFetching}
                    onRowClick={(e, rowData) =>
                        history.push({
                            pathname: `areols/${rowData.item.id}`,
                            state: {
                                data: rowData,
                            },
                        })
                    }
                    options={{
                        rowStyle: rowData => ({
                            backgroundColor: Math.abs(rowData.tableData.id % 2) === 1 ? 'white' : '#EDF1F7',
                        }),
                        actionsColumnIndex: 4,
                    }}
                    localization={{
                        header: { actions: '' },
                        body: { emptyDataSourceMessage: 'Данных нет' },
                    }}
                />
                {!isFetching && (
                    <div className="pagination-container">
                        <Paginator
                            limit={1}
                            offset={areolsList && areolsList.pagination.current - 1}
                            total={areolsList && areolsList.pagination.last}
                            onClick={(e, offset) => this.changePageAction(offset)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    areolsList: state.areolsReducer.areolsList,
    isFetching: state.areolsReducer.isFetching,
    vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
    getAreolsListAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AreolsPage));
