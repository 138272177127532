import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Typography, Button } from '@material-ui/core';
import { withRouter } from 'react-router-dom';

import { DataTable } from '../../components/DataTable';
import Paginator from '../../components/Paginator';
import AddAdminDialog from '../../components/AddAdminDialog';

import { getAdminsListAction, changeAdminStatus } from '../../actions/adminsActions';

import './style.scss';

class AdminsPage extends Component {
    state = {
        showAddAdminDialog: false,
    };

    tableData = () => {
        const { adminsList } = this.props.adminsListData;
        if (adminsList) {
            return adminsList.users.map(item => ({
                name: item.firstName || item.lastName ? item.firstName + ' ' + item.lastName : 'Unknown',
                email: item.email,
                itemId: item.id,
                isActive: item.isActive,
                status: item.isActive ? 'active' : 'disable',
            }));
        } else return [];
    };

    setAdminStatus = (id, status) => {
        this.props.changeAdminStatus(id, !status);
    };

    changePageAction = page => {
        this.props.changeAdminStatus(page + 1, true);
    };

    componentDidMount() {
        const { getAdminsListAction, history, userData } = this.props;
        if (userData.user && !userData.user.isSuperAdmin) {
            history.push('/users');
            return;
        }
        getAdminsListAction(1, true);
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.userData !== this.props.userData && this.props.userData) {
            if (!this.props.userData.user.isSuperAdmin) {
                this.props.history.push('/users');
            }
        }
    }

    render() {
        const { isFetching, adminsListData, vocabulary } = this.props;
        const { adminsList } = adminsListData;
        const { showAddAdminDialog } = this.state;
        const {
            v_admins,
            v_add_admin,
            v_name,
            v_email,
            v_account_status,
            v_edit,
            v_cancel,
            v_save,
            v_active,
            v_disabled,
            v_empty_data,
        } = vocabulary;
        return (
            <div className="users-page_wrapper">
                <div className="users-page_header">
                    <Typography variant="h5">{v_admins}</Typography>
                    <Button
                        size="small"
                        color="primary"
                        variant="contained"
                        onClick={() => this.setState({ showAddAdminDialog: true })}
                    >
                        {v_add_admin}
                    </Button>
                    <AddAdminDialog
                        dialogActive={showAddAdminDialog}
                        vocabulary={vocabulary}
                        closeDialog={() => {
                            this.setState({ showAddAdminDialog: false });
                            this.props.getAdminsListAction(1, true);
                        }}
                    />
                </div>
                <DataTable
                    columns={[
                        {
                            title: v_name,
                            field: 'name',
                            editable: 'never',
                            headerStyle: { width: 400, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                        },
                        {
                            title: v_email,
                            field: 'email',
                            editable: 'never',
                            headerStyle: { width: 400, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                        },
                        {
                            title: v_account_status,
                            field: 'status',
                            lookup: { active: v_active, disable: v_disabled },
                        },
                    ]}
                    data={this.tableData()}
                    isLoading={isFetching}
                    editable={{
                        onRowUpdate: (newData, oldData) =>
                            new Promise((resolve, reject) => {
                                setTimeout(() => {
                                    if (newData.status !== oldData.status) {
                                        this.setAdminStatus(newData.itemId, newData.isActive);
                                    }
                                    resolve();
                                }, 1000);
                            }),
                    }}
                    options={{
                        actionsColumnIndex: 4,
                    }}
                    localization={{
                        header: { actions: '' },
                        body: {
                            emptyDataSourceMessage: v_empty_data,
                            editTooltip: v_edit,
                            editRow: {
                                cancelTooltip: v_cancel,
                                saveTooltip: v_save,
                            },
                        },
                    }}
                />
                {!isFetching && adminsList && adminsList.pagination.last > 1 && (
                    <div className="pagination-container">
                        <Paginator
                            limit={1}
                            offset={adminsList.pagination.current - 1}
                            total={adminsList.pagination.last}
                            onClick={(e, offset) => this.changePageAction(offset)}
                        />
                    </div>
                )}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.userReducer,
    adminsListData: state.adminsReducer,
    isFetching: state.adminsReducer.isFetching,
    vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
    getAdminsListAction,
    changeAdminStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AdminsPage));
