import { GraphQLClient } from 'graphql-request';
import { getTokenFromLocalStorage } from './userToken';

export const initConnection = (withHeader = true) => {
    return new GraphQLClient(
        'https://api.areol.lazy-ants.com/graphiql/graphql/admin',
        withHeader && {
            headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${getTokenFromLocalStorage()}`,
            },
        }
    );
};
