export const queryAreolDetail = `
query areolById($areolId: Int!, $viewedComplains: Boolean!){
    areolById(id: $areolId){
        id
        title
        latitude
        longitude
        isActive
        complains(onlyCurrentAreol: true, viewed: $viewedComplains){
            complains{
              id
              text
              creator{
                  id
                  phone
                  firstName
                  lastName
              }
              isViewed
            }
        }
        creator{
            id
            phone
            firstName
            lastName
        }
    }
  }
`;

export const queryAreolStatus = `
  mutation setAreolStatus($areolId: Int!, $areolStatus: Boolean!){
    setStatusAreolAdmin(id: $areolId, status: $areolStatus){
        id
      }
  }
`;
