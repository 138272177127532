import React, { Component } from 'react';

import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import './App.scss';
import AuthPage from './pages/AuthPage';
import PrivateRoute from './components/PrivateRoutes/PrivateRoute';
import AdminsPage from './pages/AdminsPage';
import UsersPage from './pages/UsersPage';
import AreolsPage from './pages/AreolsPage';
import PageTemplate from './components/PageTemplate';
import AreolDetailPage from './pages/AreolDetailPage';
import UserDetailPage from './pages/UserDetailPage';
import ComplainsPage from './pages/ComplainsPage';

class App extends Component {
    render() {
        return (
            <Switch>
                <Route exact path="/" render={() => <Redirect to={'/login'} />} />
                <Route exact path="/complaints" render={() => <Redirect to={'/complaints/new'} />} />
                <Route exact path="/login" render={() => <AuthPage />} />
                <PrivateRoute exact path="/admins" render={() => <PageTemplate component={AdminsPage} />} />
                <PrivateRoute exact path="/users" render={() => <PageTemplate component={UsersPage} />} />
                <PrivateRoute exact path="/areols" render={() => <PageTemplate component={AreolsPage} />} />
                <PrivateRoute exact path="/areols/:id" render={() => <PageTemplate component={AreolDetailPage} />} />
                <PrivateRoute exact path="/users/:id" render={() => <PageTemplate component={UserDetailPage} />} />
                <PrivateRoute
                    exact
                    path="/complaints/:type"
                    render={() => <PageTemplate component={ComplainsPage} />}
                />
            </Switch>
        );
    }
}

export default withRouter(App);
