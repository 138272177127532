import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';

import LeftBar from '../LeftBar';
import HeaderBar from '../HeaderBar';

import './style.scss';

class PageTemplate extends Component {
    render() {
        const { component: Component } = this.props;
        return (
            <div className="wrapper-page-template">
                <HeaderBar />
                <main className="wrapper-main">
                    <LeftBar />
                    <div className="page-template-content">
                        <Component />
                    </div>
                </main>
            </div>
        );
    }
}

export default withRouter(PageTemplate);
