import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Typography, Button, CircularProgress } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';

// import Paginator from '../../components/Paginator';
import { DataTable } from '../../components/DataTable';

import { getUserDetailAction, changeUserStatus } from '../../actions/userDetailActions';

import './style.scss';

class UserDetailPage extends Component {
    tableData = () => {
        const { userDetail } = this.props.userDetail;
        if (userDetail) {
            return userDetail.areols.map(item => ({
                name: item.title ? item.title : '-',
                coordinates: item.longitude + ' ' + item.latitude,
                itemId: item.id,
            }));
        } else return [];
    };

    setUserStatus = () => {
        const { changeUserStatus } = this.props;
        const { userDetail } = this.props.userDetail;
        changeUserStatus(userDetail.id, !userDetail.isActive);
    };

    componentDidMount() {
        const { history, getUserDetailAction, match } = this.props;
        getUserDetailAction(match.params.id, history);
    }

    render() {
        const { history, vocabulary } = this.props;
        const { userDetail, isFetching } = this.props.userDetail;
        const {
            v_user,
            v_disable_user,
            v_activate_user,
            v_name,
            v_phone,
            v_user_areols,
            v_areol_name,
            v_areol_coordinates,
            v_empty_data,
        } = vocabulary;

        return isFetching && !userDetail ? (
            <CircularProgress size={60} className="loading-icon" />
        ) : (
            <div className="users-detail-page_wrapper">
                <div className="users-detail-page_header">
                    <div className="users-detail-page_header-title">
                        <ArrowBackIcon className="arrow-back" onClick={() => history.goBack()} />
                        <Typography variant="h5">{v_user}</Typography>
                    </div>
                    <div className="users-detail-page_header-action">
                        {isFetching && <CircularProgress size={24} />}
                        <Button
                            disabled={isFetching}
                            size="small"
                            color="primary"
                            variant="contained"
                            onClick={this.setUserStatus}
                        >
                            {userDetail && userDetail.isActive ? v_disable_user : v_activate_user}
                        </Button>
                    </div>
                </div>
                {userDetail && (
                    <div className="users-detail-page_info">
                        <div className="user-info">
                            <span className="user-info-title">{v_name + ':'}</span>
                            <span className="user-data">
                                {userDetail.firstName || userDetail.lastName
                                    ? userDetail.firstName + ' ' + userDetail.lastName
                                    : '-'}
                            </span>
                        </div>
                        <div className="user-info">
                            <span className="user-info-title">{v_phone + ':'}</span>
                            <span className="user-data">{userDetail.phone}</span>
                        </div>
                    </div>
                )}
                <Typography variant="h6" className="users-detail-page_areols-title">
                    {v_user_areols}
                </Typography>
                <DataTable
                    columns={[
                        {
                            title: v_areol_name,
                            field: 'name',
                            headerStyle: { width: 400, borderRight: '1px solid rgba(224, 224, 224, 1)' },
                        },
                        {
                            title: v_areol_coordinates,
                            field: 'coordinates',
                            headerStyle: { borderRight: '1px solid rgba(224, 224, 224, 1)' },
                        },
                    ]}
                    data={this.tableData()}
                    isLoading={isFetching}
                    options={{
                        rowStyle: rowData => ({
                            backgroundColor: Math.abs(rowData.tableData.id % 2) === 1 ? 'white' : '#EDF1F7',
                        }),
                        actionsColumnIndex: 4,
                    }}
                    localization={{
                        header: { actions: '' },
                        body: { emptyDataSourceMessage: v_empty_data },
                    }}
                />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    userDetail: state.userDetailReducer,
    vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
    getUserDetailAction,
    changeUserStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(UserDetailPage));
