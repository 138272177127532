import { queryComplainsList, queryChangeComplainStatus } from '../queries/complainsQueries';
import { initConnection } from '../services/apiConfig';

const getComplainsListData = () => ({
    type: 'GET_COMPLAINS_LIST_DATA',
});
const getComplainsListDataSuccess = payload => ({
    type: 'GET_COMPLAINS_LIST_DATA_SUCCESS',
    payload,
});
const getComplainsListDataError = () => ({
    type: 'GET_COMPLAINS_LIST_DATA_ERROR',
});

export const getComplainsListAction = (page, newComplains) => async dispatch => {
    dispatch(getComplainsListData());
    try {
        let res = await initConnection().request(queryComplainsList, {
            page,
            newComplains,
        });
        let complainsPayload = res.admin.complains;
        dispatch(getComplainsListDataSuccess(complainsPayload));
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getComplainsListDataError());
    }
};

export const changeComplainStatus = (complainlId, status, page) => async dispatch => {
    dispatch(getComplainsListData());
    try {
        let res = await initConnection().request(queryChangeComplainStatus, {
            complainlId,
            status,
        });
        if (res.setStatusComplainAdmin) {
            dispatch(getComplainsListAction(page, true));
        }
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getComplainsListDataError());
    }
};
