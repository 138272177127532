const initialState = {
    user: null,
    isFetching: false,
    userAuth: false,
    isInitialFetching: true,
};

export default (state = initialState, { type, payload }) => {
    switch (type) {
        case 'GET_USER_DATA': {
            return {
                ...state,
                isFetching: true,
            };
        }
        case 'GET_USER_DATA_SUCCESS': {
            return {
                isFetching: false,
                user: payload,
                userAuth: true,
                isInitialFetching: false,
            };
        }
        case 'GET_USER_DATA_ERROR': {
            return {
                user: null,
                isFetching: false,
                userAuth: false,
            };
        }
        case 'RESET_ALL': {
            return {
                ...initialState,
            };
        }
        default:
            return state;
    }
};
