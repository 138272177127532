import React, { Component } from 'react';
// import { connect } from 'react-redux';
import { Formik } from 'formik';
import * as Yup from 'yup';
import './style.scss';

import { queryAddAdmin } from '../../queries/adminsQueries';
import { initConnection } from '../../services/apiConfig';

import {
    Button,
    TextField,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    CircularProgress,
} from '@material-ui/core';

class AddAdminDialog extends Component {
    state = {
        isFetching: false,
    };
    addAdmin = async (email, password) => {
        this.setState({ isFetching: true });
        try {
            let res = await initConnection().request(queryAddAdmin, {
                email,
                password,
            });
            if (res.createAdmin) {
                this.setState({ isFetching: false });
                this.props.closeDialog();
            }
        } catch (error) {
            if (error.response.errors) {
                alert(error.response.errors[0].message);
                this.setState({ isFetching: false });
            }
        }
    };
    render() {
        const { dialogActive, closeDialog, vocabulary } = this.props;
        const { isFetching } = this.state;
        const { v_add_admin, v_email, v_cancel, v_password, v_add, v_v_wrong_email, v_v_required } = vocabulary;
        return (
            <Dialog
                open={dialogActive}
                onClose={closeDialog}
                aria-labelledby="form-dialog-title"
                className="dialog-container"
            >
                <DialogTitle id="form-dialog-title" className="dialog-title">
                    {v_add_admin}
                </DialogTitle>
                <Formik
                    validateOnChange={false}
                    validateOnBlur={false}
                    initialValues={{ email: '', password: '' }}
                    validationSchema={Yup.object({
                        email: Yup.string()
                            .email(v_v_wrong_email)
                            .required(v_v_required),
                        password: Yup.string().required(v_v_required),
                    })}
                    onSubmit={(values, { setSubmitting }) => {
                        this.addAdmin(values.email, values.password);
                        setSubmitting(false);
                    }}
                >
                    {formik => (
                        <form noValidate onSubmit={formik.handleSubmit}>
                            <DialogContent>
                                <TextField
                                    error={!!formik.errors.email}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    label={v_email}
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.eamil}
                                />
                                <TextField
                                    error={!!formik.errors.password}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label={v_password}
                                    type="password"
                                    id="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={closeDialog} color="primary">
                                    {isFetching ? <CircularProgress size={24} /> : v_cancel}
                                </Button>
                                <Button type="submit" className="submit-btn" color="primary" disabled={isFetching}>
                                    {isFetching ? <CircularProgress size={24} /> : v_add}
                                </Button>
                            </DialogActions>
                        </form>
                    )}
                </Formik>
            </Dialog>
        );
    }
}

export default AddAdminDialog;
