import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter, Redirect } from 'react-router-dom';
import { Formik } from 'formik';
import * as Yup from 'yup';
import { Container, Typography, TextField, Button, CircularProgress } from '@material-ui/core';

import { getUserDataAction, checkUserAuth } from '../../actions/userActions';
import { setLanguage } from '../../actions/languageActions';

import './style.scss';

class AuthPage extends Component {
    login = (email, password) => {
        this.props.getUserDataAction({ email, password });
    };
    changeLanguage = lang => {
        const { vocabulary, setLanguage } = this.props;
        if (lang === vocabulary.language.short) return;
        else {
            setLanguage(lang);
        }
    };

    componentDidMount() {
        this.props.checkUserAuth();
    }

    render() {
        const { language, vocabulary } = this.props;
        const { isFetching, userAuth } = this.props.userData;

        const { v_signin, v_email, v_password, v_enter, v_v_required, v_v_wrong_email } = vocabulary;

        if (userAuth) return <Redirect to={'/users'} />;
        return (
            <Container component="main" maxWidth="xs">
                <div className="change-language-container">
                    {language.languages.map((item, index) => (
                        <div key={index} onClick={e => this.changeLanguage(item.short)}>
                            {item.short.toUpperCase()}
                        </div>
                    ))}
                </div>
                <div className="wrapper-auth-page">
                    <Typography component="h1" variant="h5">
                        {v_signin}
                    </Typography>
                    <Formik
                        validateOnChange={false}
                        validateOnBlur={false}
                        initialValues={{ email: '', password: '' }}
                        validationSchema={Yup.object({
                            email: Yup.string()
                                .email(v_v_wrong_email)
                                .required(v_v_required),
                            password: Yup.string().required(v_v_required),
                        })}
                        onSubmit={(values, { setSubmitting }) => {
                            this.login(values.email, values.password);
                            setSubmitting(false);
                        }}
                    >
                        {formik => (
                            <form noValidate onSubmit={formik.handleSubmit}>
                                <TextField
                                    error={!!formik.errors.email}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    id="email"
                                    label={v_email}
                                    name="email"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.eamil}
                                />
                                <TextField
                                    error={!!formik.errors.password}
                                    variant="outlined"
                                    margin="normal"
                                    fullWidth
                                    name="password"
                                    label={v_password}
                                    type="password"
                                    id="password"
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    value={formik.values.password}
                                />
                                <Button
                                    type="submit"
                                    className="submit-btn"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    disabled={isFetching}
                                >
                                    {isFetching ? <CircularProgress size={24} /> : v_enter}
                                </Button>
                            </form>
                        )}
                    </Formik>
                </div>
            </Container>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.userReducer,
    language: state.languageReducer,
    vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
    getUserDataAction,
    checkUserAuth,
    setLanguage,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AuthPage));
