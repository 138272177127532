import { combineReducers } from 'redux';

import userReducer from './userReducer';
import userListReducer from './usersListReducer';
import adminsReducer from './adminsReducer';
import areolsReducer from './areolsReducer';
import userDetailReducer from './userDetailReducer';
import areolDetailReducer from './areolDetailReducer';
import complainsReducer from './complainsReducer';
import languageReducer from './languageReducer';

export const rootReducer = combineReducers({
    userReducer,
    userListReducer,
    adminsReducer,
    areolsReducer,
    userDetailReducer,
    areolDetailReducer,
    complainsReducer,
    languageReducer,
});
