export default {
    language: {
        long: 'English',
        short: 'en',
    },
    v_admins: 'Admins',
    v_add_admin: 'Add admin',
    v_name: 'Name and surname',
    v_account_status: 'Accaunt status',

    v_users: 'Users',
    v_user: 'User',
    v_user_areols: 'User areols',
    v_disable_user: 'Disable user',
    v_activate_user: 'Activate user',

    v_areols: 'Areols',
    v_areol: 'Areol',
    v_areol_name: 'Areol name',
    v_areol_author: 'Areol author',
    v_areol_coordinates: 'Areol coordinates',
    v_author: 'Author',
    v_disable_areol: 'Disable areol',
    v_activate_areol: 'Activate areol',
    v_areol_author_phone: 'Areol author phone',

    v_complaints: 'Complaints',
    v_no_complaints: 'No complaints',
    v_complaint_author: 'Complaints author',
    v_complaint: 'Complaint',
    v_new: 'New',
    v_watched: 'Watched',
    v_cancel_complaint: 'Cancel complaint',
    v_complaint_author_phone: 'Complaints author phone',

    v_logout: 'Logout',
    v_email: 'Email',
    v_phone: 'Phone number',
    v_edit: 'Edit',
    v_cancel: 'Cancel',
    v_save: 'Save',
    v_active: 'Active',
    v_disabled: 'Disabled',
    v_empty_data: 'Empty data',
    v_password: 'Password',
    v_add: 'Add',
    v_action: 'Action',
    v_change_language: 'Change language',
    v_signin: 'Sign in',
    v_enter: 'Sign in',

    v_v_wrong_email: 'Wrong email format',
    v_v_required: 'Required field',
};
