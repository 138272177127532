export const queryAuth = `
  mutation userAuth($email: String!, $password: String!){
    loginAdmin(
      email: $email,
      password: $password
    ){
      token
    }
  }
`;

export const queryGetUserLanguage = `
query getAdminUser{
  getAdminUser{
    locale
  }
}
`;

export const querySetUserLanguage = `
mutation setUserLanguage($lang: String!){
  setAdminLocale(locale: $lang){
    locale
  }
}
`;
