import { initConnection } from '../services/apiConfig';
import { querySetUserLanguage } from '../queries/authQueries';

export const setLanguage = payload => ({
    type: 'CHANGE_LANGUAGE',
    payload,
});

const changeLanguageRequest = () => ({
    type: 'CHANGE_LANGUAGE_REQUEST',
});

const changeLanguageRequestError = () => ({
    type: 'CHANGE_LANGUAGE_REQUEST_ERROR',
});

export const setLanguageAction = lang => async dispatch => {
    dispatch(changeLanguageRequest());
    try {
        let langRes = await initConnection().request(querySetUserLanguage, { lang });
        if (langRes.setAdminLocale.locale) {
            dispatch(setLanguage(langRes.setAdminLocale.locale));
        } else {
            dispatch(setLanguage(lang));
        }
    } catch (error) {
        if (error.response && error.response.errors) {
            alert(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(changeLanguageRequestError());
    }
};
