export default {
    language: {
        long: 'Русский',
        short: 'ru',
    },
    v_admins: 'Администраторы',
    v_add_admin: 'Добавить администратора',
    v_name: 'Имя и фамилия',
    v_account_status: 'Статус аккаунта',

    v_users: 'Пользователи',
    v_user: 'Пользователь',
    v_user_areols: 'Ареолы пользователя',
    v_disable_user: 'Заблокировать пользователя',
    v_activate_user: 'Активировать пользователя',

    v_areols: 'Ареолы',
    v_areol: 'Ареол',
    v_areol_name: 'Название ареола',
    v_areol_author: 'Автор ареола',
    v_areol_coordinates: 'Координаты ареола',
    v_author: 'Автор',
    v_disable_areol: 'Заблокировать ареол',
    v_activate_areol: 'Активировать ареол',
    v_areol_author_phone: 'Номер телефона автора ареола',

    v_complaints: 'Жалобы',
    v_complaint: 'Жалоба',
    v_complaint_author: 'Автор жалобы',
    v_no_complaints: 'Жалобы отсутствуют',
    v_new: 'Новые',
    v_watched: 'Просмотренные',
    v_cancel_complaint: 'Отменить жалобу',
    v_complaint_author_phone: 'Номер телефона автора жалобы',

    v_logout: 'Выйти',
    v_email: 'Email адрес',
    v_phone: 'Номер телефона',
    v_edit: 'Редактировать',
    v_cancel: 'Отмена',
    v_save: 'Сохранить',
    v_active: 'Активен',
    v_disabled: 'Не активен',
    v_empty_data: 'Данных нет',
    v_password: 'Пароль',
    v_add: 'Добавить',
    v_action: 'Действие',
    v_change_language: 'Сменить язык',
    v_signin: 'Авторизация',
    v_enter: 'Войти',

    v_v_wrong_email: 'Неправильный формат email',
    v_v_required: 'Обязательное поле',
};
