export const queryUserList = `
  query admin($page: Int){
    admin{
      users(page: $page) {
        users {
          id
          phone
          firstName
          lastName
          isActive
        }
        pagination {
          last
          current
          numItemsPerPage
          first
        }
      }
    }
  }
`;

export const queryChangeUserStatus = `
mutation setStatusUser($userId:Int!, $status: Boolean!){
  setStatusUserAdmin(id: $userId, status: $status){
    id
  }
}
`;

export const queryUserAreols = `
query oneById($userId: Int!){
  oneById(id: $userId){
    areols{
      id
      title
      longitude
      latitude
      altitude
    }
  }
}
`;
