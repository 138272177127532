import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Typography, AppBar, Toolbar } from '@material-ui/core';

import { userLogoutAction } from '../../actions/userActions';

import './style.scss';

class HeaderBar extends Component {
    userLogout = event => {
        const { userLogoutAction, history } = this.props;
        userLogoutAction();
        history.push('/login');
    };
    render() {
        const { vocabulary } = this.props;
        const { user } = this.props.userData;
        const { v_logout } = vocabulary;
        return (
            <AppBar>
                <Toolbar className="header-bar">
                    <Typography variant="h6">AREOL:ADMIN</Typography>
                    <div className="header-bar-user">
                        {user && user.userName}
                        <div className="header-bar-logout" onClick={this.userLogout}>
                            {v_logout}
                        </div>
                    </div>
                </Toolbar>
            </AppBar>
        );
    }
}

const mapStateToProps = state => ({
    userData: state.userReducer,
    vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
    userLogoutAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(HeaderBar));
