import { queryAdminsList, queryAdminStatus } from '../queries/adminsQueries';
import { initConnection } from '../services/apiConfig';

const getAdminsListData = () => ({
    type: 'GET_ADMINS_LIST_DATA',
});
const getAdminsListDataSuccess = payload => ({
    type: 'GET_ADMINS_LIST_DATA_SUCCESS',
    payload,
});
const getAdminsListDataError = () => ({
    type: 'GET_ADMINS_LIST_DATA_ERROR',
});

export const getAdminsListAction = (page, onlyAdmins) => async dispatch => {
    dispatch(getAdminsListData());
    try {
        let res = await initConnection().request(queryAdminsList, {
            page,
            onlyAdmins,
        });
        let adminsPayload = res.admin.users;
        dispatch(getAdminsListDataSuccess(adminsPayload));
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getAdminsListDataError());
    }
};

export const changeAdminStatus = (adminId, adminStatus) => async dispatch => {
    dispatch(getAdminsListData());
    try {
        let res = await initConnection().request(queryAdminStatus, {
            adminId,
            adminStatus,
        });
        if (res.setStatusUserAdmin) {
            dispatch(getAdminsListAction(1, true));
        }
    } catch (error) {
        if (error.response && error.response.errors) {
            console.log(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getAdminsListDataError());
    }
};
