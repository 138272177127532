import React, { Component } from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import { List, ListItem, ListItemText, CircularProgress } from '@material-ui/core';

import { setLanguageAction } from '../../actions/languageActions';

import './style.scss';

class LeftBar extends Component {
    changeLanguage = lang => {
        const { vocabulary, setLanguageAction } = this.props;
        if (lang === vocabulary.language.short) return;
        else {
            setLanguageAction(lang);
        }
    };

    render() {
        const { match, userData, language, vocabulary, isFetching } = this.props;
        const { v_admins, v_users, v_areols, v_complaints, v_change_language } = vocabulary;
        return (
            <div className="wrapper-left-bar">
                <List component="nav">
                    {userData.user && userData.user.isSuperAdmin && (
                        <NavLink to="/admins" activeClassName={null}>
                            <ListItem button selected={match.path.indexOf('/admins') >= 0}>
                                <ListItemText primary={v_admins} />
                            </ListItem>
                        </NavLink>
                    )}
                    <NavLink to="/users">
                        <ListItem button selected={match.path.indexOf('/users') >= 0}>
                            <ListItemText primary={v_users} />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/areols">
                        <ListItem button selected={match.path.indexOf('/areols') >= 0}>
                            <ListItemText primary={v_areols} />
                        </ListItem>
                    </NavLink>
                    <NavLink to="/complaints">
                        <ListItem button selected={match.path.indexOf('/complaints') >= 0}>
                            <ListItemText primary={v_complaints} />
                        </ListItem>
                    </NavLink>
                </List>
                <div className="change-language-container-main">
                    <div className="change-language-title">{v_change_language + ':'}</div>
                    {isFetching ? (
                        <CircularProgress size={16} />
                    ) : (
                        language.languages.map((item, index) => (
                            <span key={index} onClick={e => this.changeLanguage(item.short)}>
                                {item.long}
                            </span>
                        ))
                    )}
                </div>
            </div>
        );
    }
}
const mapStateToProps = state => ({
    userData: state.userReducer,
    language: state.languageReducer,
    vocabulary: state.languageReducer.vocabulary,
    isFetching: state.languageReducer.isFetching,
});
const mapDispatchToProps = {
    setLanguageAction,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LeftBar));
