import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { Typography, Button, CircularProgress, Card } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import classNames from 'classnames';

import { getAreolDetailAction, changeAreolStatus, changeAreolComplainStatus } from '../../actions/areolDetailActions';

import './style.scss';

class AreolDetailPage extends Component {
    state = {
        viewedComplains: false,
    };

    setAreolStatus = () => {
        const { changeAreolStatus, history } = this.props;
        const { areolDetail } = this.props.areolDetail;
        changeAreolStatus(areolDetail.id, !areolDetail.isActive, history);
    };

    setStatusComplain = complainId => {
        const { changeAreolComplainStatus, history } = this.props;
        const { areolDetail } = this.props.areolDetail;
        changeAreolComplainStatus(complainId, areolDetail.id, history);
    };

    switchComplainsType = () => {
        const { match, getAreolDetailAction, history } = this.props;
        this.setState(
            prevState => ({
                viewedComplains: !prevState.viewedComplains,
            }),
            () => {
                getAreolDetailAction(match.params.id, history, this.state.viewedComplains);
            }
        );
    };

    componentDidMount() {
        const { match, getAreolDetailAction, history } = this.props;
        const { viewedComplains } = this.state;
        getAreolDetailAction(match.params.id, history, viewedComplains);
    }

    render() {
        const { history, vocabulary } = this.props;
        const { areolDetail, isFetching } = this.props.areolDetail;
        const { viewedComplains } = this.state;

        const {
            v_areol,
            v_areol_author,
            v_areol_coordinates,
            v_disable_areol,
            v_activate_areol,
            v_complaints,
            v_no_complaints,
            v_new,
            v_watched,
            v_complaint,
            v_complaint_author,
            v_cancel_complaint,
        } = vocabulary;

        return isFetching && !areolDetail ? (
            <CircularProgress size={60} className="loading-icon" />
        ) : (
            areolDetail && (
                <div className="areol-detail-page_wrapper">
                    <div className="areol-detail-page_header">
                        <div className="areol-detail-page_header-title">
                            <ArrowBackIcon className="arrow-back" onClick={() => history.goBack()} />
                            <Typography variant="h5">{v_areol + ': ' + areolDetail.title}</Typography>
                        </div>
                        <div className="areol-detail-page_header-action">
                            {isFetching && <CircularProgress size={24} />}
                            <Button
                                disabled={isFetching}
                                size="small"
                                color="primary"
                                variant="contained"
                                onClick={this.setAreolStatus}
                            >
                                {areolDetail.isActive ? v_disable_areol : v_activate_areol}
                            </Button>
                        </div>
                    </div>

                    <div className="areol-detail-page_info">
                        <div className="areol-info">
                            <span className="areol-info-title">{v_areol_author + ':'}</span>
                            <Link to={`/users/${areolDetail.creator.id}`} className="areol-creator-name">
                                {areolDetail.creator.firstName || areolDetail.creator.lastName
                                    ? areolDetail.creator.firstName + ' ' + areolDetail.creator.lastName
                                    : areolDetail.creator.phone}
                            </Link>
                        </div>
                        <div className="areol-info">
                            <span className="areol-info-title">{v_areol_coordinates + ':'}</span>
                            <span className="areol-data">{areolDetail.longitude + ' ' + areolDetail.latitude}</span>
                        </div>
                    </div>

                    <div className="areols-detail-page_complains-header">
                        <Typography variant="h6" className="areols-detail-page_complains-title">
                            {v_complaints + ':'}
                        </Typography>
                        <span
                            className={classNames({ 'areols-detail-page_complains-link': viewedComplains })}
                            onClick={() => (viewedComplains ? this.switchComplainsType() : null)}
                        >
                            {v_new}
                        </span>
                        <span
                            className={classNames({ 'areols-detail-page_complains-link': !viewedComplains })}
                            onClick={() => (!viewedComplains ? this.switchComplainsType() : null)}
                        >
                            {v_watched}
                        </span>
                    </div>

                    <div className="areols-detail-page_complains-wrapper">
                        {isFetching && (
                            <div className="loading-container">
                                <CircularProgress />
                            </div>
                        )}
                        <div className="areols-detail-page_complains-container">
                            {areolDetail.complains.complains.length ? (
                                areolDetail.complains.complains.map((complain, index) => (
                                    <Card
                                        key={index}
                                        className={classNames('users-detail-page_complain-card', {
                                            'users-detail-page_complain-card-viewed': complain.isViewed,
                                        })}
                                    >
                                        <div className="complain-header">
                                            <div className="complain-title">
                                                <div className="complain-id">{v_complaint + ' ' + complain.id}</div>
                                                <div>
                                                    <span className="complain-creator-title">
                                                        {v_complaint_author + ': '}
                                                    </span>
                                                    <Link
                                                        to={`/users/${complain.creator.id}`}
                                                        className="complain-creator-name"
                                                    >
                                                        {complain.creator.firstName || complain.creator.lastName
                                                            ? complain.creator.firstName +
                                                              ' ' +
                                                              complain.creator.lastName
                                                            : complain.creator.phone}
                                                    </Link>
                                                </div>
                                            </div>
                                            {!complain.isViewed &&
                                                (isFetching && areolDetail.id ? null : (
                                                    <div
                                                        className="complain-action"
                                                        onClick={() =>
                                                            !isFetching && this.setStatusComplain(complain.id)
                                                        }
                                                    >
                                                        {v_cancel_complaint}
                                                    </div>
                                                ))}
                                        </div>
                                        <div className="complain-text">{complain.text}</div>
                                    </Card>
                                ))
                            ) : (
                                <div>{v_no_complaints}</div>
                            )}
                        </div>
                    </div>
                </div>
            )
        );
    }
}

const mapStateToProps = state => ({
    areolDetail: state.areolDetailReducer,
    vocabulary: state.languageReducer.vocabulary,
});

const mapDispatchToProps = {
    getAreolDetailAction,
    changeAreolStatus,
    changeAreolComplainStatus,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AreolDetailPage));
