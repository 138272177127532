import { initConnection } from '../services/apiConfig';
import { queryAuth, queryGetUserLanguage } from '../queries/authQueries';
import {
    setTokenToLocalStorage,
    tokenDecode,
    getTokenFromLocalStorage,
    removeTokenFromLocalStorage,
} from '../services/userToken';

import { setLanguage } from './languageActions';

const getUserData = () => ({
    type: 'GET_USER_DATA',
});
const getUserDataSuccess = payload => ({
    type: 'GET_USER_DATA_SUCCESS',
    payload,
});
const getUserDataError = () => ({
    type: 'GET_USER_DATA_ERROR',
});
const logout = () => ({
    type: 'RESET_ALL',
});

export const getUserDataAction = ({ email, password }) => async dispatch => {
    dispatch(getUserData());
    try {
        let res = await initConnection(false).request(queryAuth, { email, password });
        let userToken = res.loginAdmin.token;
        if (userToken) {
            let decodedData = tokenDecode(userToken);
            if (decodedData.roles[0] === 'ROLE_USER') {
                dispatch(getUserDataError());
                alert('Incorrect email or password');
                return;
            }
            setTokenToLocalStorage(userToken);
            let langRes = await initConnection().request(queryGetUserLanguage);
            if (langRes.getAdminUser.locale) {
                dispatch(setLanguage(langRes.getAdminUser.locale));
            }
            dispatch(
                getUserDataSuccess({
                    isSuperAdmin: decodedData.roles[0] === 'ROLE_SUPER_ADMIN' ? true : false,
                    userName: decodedData.username,
                })
            );
        } else {
            dispatch(getUserDataError());
            alert('Incorrect email or password');
        }
    } catch (error) {
        if (error.response && error.response.errors) {
            alert(error.response.errors[0].message);
        } else {
            console.log(error);
        }
        dispatch(getUserDataError());
    }
};

export const checkUserAuth = () => async dispatch => {
    let userToken = getTokenFromLocalStorage();
    if (userToken) {
        let decodedData = tokenDecode(userToken);
        let langRes = await initConnection().request(queryGetUserLanguage);
        if (langRes.getAdminUser.locale) {
            dispatch(setLanguage(langRes.getAdminUser.locale));
        }
        dispatch(
            getUserDataSuccess({
                isSuperAdmin: decodedData.roles[0] === 'ROLE_SUPER_ADMIN' ? true : false,
                userName: decodedData.username,
            })
        );
    } else {
        dispatch(getUserDataError());
    }
};

export const userLogoutAction = () => dispatch => {
    removeTokenFromLocalStorage();
    dispatch(logout());
};
