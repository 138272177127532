export const queryAdminsList = `
  query admin($page: Int, $onlyAdmins: Boolean){
    admin{
      users(page: $page, onlyAdmin: $onlyAdmins) {
        users {
          id
          email
          firstName
          lastName
          isActive
        }
        pagination {
          last
          current
          numItemsPerPage
          first
        }
      }
    }
  }
`;

export const queryAdminStatus = `
mutation setAdminStatus($adminId: Int!, $adminStatus: Boolean!){
  setStatusUserAdmin(id: $adminId, status: $adminStatus){
    email
  }
  }
`;

export const queryAddAdmin = `
mutation addAdmin($email: String!, $password: String!){
  createAdmin(email: $email, password: $password){
    id
    email
    phone
  }
  }
`;
