export const queryAreolsList = `
  query admin($page: Int){
    admin{
      areols(page: $page) {
        areols {
            id
            title
            longitude
            latitude
            altitude
            creator {
                id
                phone
                firstName
                lastName
                isActive
            }
            isActive
        }
        pagination {
          last
          current
          numItemsPerPage
          first
        }
      }
    }
  }
`;
